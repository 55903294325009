@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  color: black;
  text-decoration: none; }

div {
  font-family: 'Sulphur Point', sans-serif;
  /* font-family: 'Francois One', sans-serif; */
  /* font-family: 'Baloo Tamma 2', cursive; */ }

/* Header Nav */
.header {
  font-size: 16px; }

/*FOOD OR DRINKS PAGE*/
.menu-choice span,
.menu-choice a {
  font-family: 'Baloo Tamma 2';
  font-size: 30px;
  font-weight: bold;
  text-shadow: 3px 3px #000;
  letter-spacing: 0.7vh;
  color: white; }

/*FOOD MENU LIST PAGE*/
.food-title {
  font-family: 'Francois One', sans-serif;
  letter-spacing: 0.8vh;
  font-size: 5vh; }
  .food-title p {
    font-family: 'Baloo Tamma 2', cursive; }

.footer {
  font-family: 'Sulphur Point', sans-serif; }

/*Slider*/
button {
  font-family: 'Sulphur Point', sans-serif; }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%; }

* {
  font-family: "Lato", Helvetica, sans-serif;
  color: #333447;
  line-height: 1.5; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8; }

.font-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-heavy {
  font-weight: 700; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.justify {
  text-align: justify; }

.hidden-sm {
  display: none; }

.container {
  width: 90%; }
  @media only screen and (min-width: 33.75em) {
    .container {
      width: 80%; } }
  @media only screen and (min-width: 60em) {
    .container {
      width: 80%; } }

.row {
  position: relative;
  width: 100%; }

.row [class^='col'] {
  float: left;
  margin: 0;
  min-height: 0.125rem; }

.row::after {
  content: '';
  display: table;
  clear: both; }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%; }

.col-1-sm {
  width: 4.33333%; }

.col-2-sm {
  width: 12.66667%; }

.col-3-sm {
  width: 21%; }

.col-4-sm {
  width: 29.33333%; }

.col-5-sm {
  width: 37.66667%; }

.col-6-sm {
  width: 46%; }

.col-7-sm {
  width: 54.33333%; }

.col-8-sm {
  width: 62.66667%; }

.col-9-sm {
  width: 71%; }

.col-10-sm {
  width: 79.33333%; }

.col-11-sm {
  width: 87.66667%; }

.col-12-sm {
  width: 96%; }

@media only screen and (min-width: 45em) {
  .col-1 {
    width: 4.33333%; }
  .col-2 {
    width: 12.66667%; }
  .col-3 {
    width: 21%; }
  .col-4 {
    width: 29.33333%; }
  .col-5 {
    width: 37.66667%; }
  .col-6 {
    width: 46%; }
  .col-7 {
    width: 54.33333%; }
  .col-8 {
    width: 62.66667%; }
  .col-9 {
    width: 71%; }
  .col-10 {
    width: 79.33333%; }
  .col-11 {
    width: 87.66667%; }
  .col-12 {
    width: 96%; }
  .hidden-sm {
    display: block; } }

.each-slide div {
  cursor: grab;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative; }

.each-slide span {
  max-width: 13%;
  margin-left: 3%;
  text-align: center;
  font-size: 4.5vh;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  top: 40%;
  position: absolute; }
  .each-slide span .signup {
    background: transparent;
    color: inherit;
    text-decoration: none;
    border: 2px solid #fff;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 3px;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    opacity: 1; }
    .each-slide span .signup:hover {
      background-color: rgba(255, 255, 255, 0.096); }

.react-slideshow-container button {
  opacity: 0; }

#BottomToTop {
  animation: BottomToTop ease 2s;
  -webkit-animation: BottomToTop ease 1.5s;
  -moz-animation: BottomToTop ease 2s;
  -o-animation: BottomToTop ease 2s;
  -ms-animation: BottomToTop ease 2s; }

@keyframes BottomToTop {
  0% {
    margin-top: 23%; }
  100% {
    margin-top: 0%; } }

@-webkit-keyframes BottomToTop {
  0% {
    margin-top: 23%; }
  100% {
    margin-top: 0%; } }

/*MOBILE VERSION OF OUR STORY PAGE*/
@media screen and (max-width: 730px) {
  .each-slide span {
    top: 20%;
    width: 100%;
    margin-left: 2%; } }

.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
  cursor: pointer; }
  .scroll-to-top img {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
    padding: 15px; }

.footer {
  position: static;
  bottom: 0px;
  width: 70%;
  text-align: center;
  margin: 80px 15%;
  line-height: 1.15; }
  .footer h3 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px; }
  .footer h4 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bolder; }
  .footer p {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px; }

hr {
  margin-top: 80px; }

.rights-reserved {
  text-align: left;
  font-size: 13px;
  padding: 30px 0 50px 0; }

.header {
  top: 0;
  background-color: rgba(255, 255, 255, 0.932);
  z-index: 10;
  width: 91%;
  padding: 15px 75px 5px 75px;
  position: fixed; }
  .header img {
    width: 205px;
    height: auto;
    display: inline-block; }
  .header .lang {
    margin-top: 35px; }

.fb-inst-links {
  padding-top: 10px;
  padding-left: 15px; }
  .fb-inst-links img {
    height: 21px;
    width: auto;
    margin-left: 20px; }
    .fb-inst-links img:hover {
      box-shadow: 2px 2px 2px rgba(29, 39, 85, 0.932), -2px -2px 4px rgba(29, 39, 85, 0.932);
      cursor: pointer; }
  .fb-inst-links .header-icons {
    margin: 15px 10px 0 0; }

.instagram-icon {
  border-radius: 6px;
  padding: 0 !important; }

.facebook-icon {
  padding: 2px; }

.icon-right {
  text-align: right; }

.icon-left {
  margin-left: 10px; }

.shop {
  margin-right: 25px; }

.restaraunt-title {
  margin-left: 15px; }

/*Last section*/
.lang {
  color: #000;
  font-weight: 900; }

/*MOBILE VERSION OF HEADER*/
.mobile-header {
  z-index: 20;
  display: none;
  position: absolute;
  right: 30px;
  top: 30px; }
  .mobile-header img {
    width: 30px;
    height: auto; }

.menubar-item {
  width: 30px;
  height: 30px; }

.mobileheader {
  background-image: url(/static/media/close.ee2dd8dd.svg);
  background-size: 25px;
  background-repeat: no-repeat; }
  .mobileheader .menubar-item .menubar-open {
    width: 100%;
    height: auto;
    display: inline-block;
    position: relative; }

.mobilesechead {
  background-image: url(/static/media/menu.78cb8ff6.svg);
  background-size: 25px;
  background-repeat: no-repeat; }
  .mobilesechead .menubar-item .menubar-open {
    display: none; }

@media screen and (max-width: 1300px) {
  .navigation {
    display: none; } }

@media screen and (max-width: 730px) {
  .mobile-header {
    display: inline; }
  .header {
    padding: 10px 5px;
    width: 100%; } }

.navigation {
  top: 0;
  position: fixed;
  z-index: 11;
  margin: 35px 0 !important;
  padding-left: 25%; }
  .navigation a:hover {
    color: #28705a; }
  .navigation li {
    padding: 10px;
    margin-right: 35px; }
  .navigation ul {
    display: flex; }

.active {
  color: #28705a !important; }

/*MOBILE VERSION OF HEADER*/
@media screen and (max-width: 1300px) {
  .navigation {
    display: none; } }

@media screen and (max-width: 730px) {
  .navigation,
  .fb-inst-links,
  .lang {
    display: none; } }

/*FOOD OR DRINKS PAGE*/
.menu-choice {
  width: 80%;
  text-align: center;
  margin: 40px 10%;
  padding-top: 150px; }

.menu-choice__drinks {
  float: left; }

.menu-choice__food {
  float: right; }

.menu-choice__ind {
  opacity: 0.8;
  transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  position: relative; }
  .menu-choice__ind img {
    width: 600px;
    height: 400px;
    object-fit: cover;
    object-position: 80% 20%; }
  .menu-choice__ind:hover {
    opacity: 1;
    cursor: pointer; }

/* White box on the image with title */
.menu-choice span {
  border: 4px solid white;
  padding: 10px 10px;
  position: absolute;
  transition: all 0.2s ease-in-out; }
  .menu-choice span:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.DrinksText {
  top: 50px;
  right: 50px; }

.FoodText {
  bottom: 50px;
  left: 50px; }

/*FOOD MENU LIST PAGE*/
#food-menu {
  padding-top: 100px; }

.food-list {
  padding-top: 50px;
  text-align: left;
  margin: auto;
  width: 40%; }
  .food-list h2 {
    bottom: 0; }
  .food-list hr {
    margin: 40px 0 10px;
    background-color: #092b0b; }

.food-item {
  padding: 0 30px 0; }
  .food-item_title {
    font-size: 17px;
    font-weight: bolder;
    color: #000;
    padding: 30px 0 10px; }
  .food-item img {
    width: 15px;
    cursor: pointer;
    float: right; }

.food-list-image {
  text-align: center;
  padding: 80px 0;
  margin: auto;
  display: block;
  width: 70%; }
  .food-list-image img {
    height: 200px;
    width: 80%;
    object-fit: cover;
    object-position: 30% 60%; }
  .food-list-image-2 img {
    object-position: 30% 30%; }

.food-section {
  width: 40%;
  margin: auto; }

.food-item {
  display: inline; }

/*MOBILE VERSION OF FOOD MENU*/
@media screen and (max-width: 730px) {
  .food-list,
  .food-section {
    width: 80%; }
  .food-list-image img {
    width: 100%; } }

.ourstory {
  padding-top: 80px;
  margin: 20px 13%; }
  .ourstory p {
    line-height: 1.5em;
    padding-right: 25px;
    padding-bottom: 2em;
    color: #404040;
    font-weight: bolder;
    font-size: 16px;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px; }
  .ourstory img {
    height: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-bottom: 1.5cem; }

/*MOBILE VERSION OF OUR STORY PAGE*/
@media screen and (max-width: 730px) {
  .ourstory {
    margin: 0 5%; } }

.joinourteam {
  margin: 20px 13%;
  padding-top: 100px; }
  .joinourteam p {
    margin-bottom: 1.5em;
    color: #404040;
    font-weight: bolder;
    font-size: 16px; }
  .joinourteam img {
    height: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-bottom: 1.5cem; }
  .joinourteam .oasis-family {
    width: 60%; }

/*MOBILE VERSION OF OUR STORY PAGE*/
@media screen and (max-width: 730px) {
  .joinourteam {
    margin: 0 2%; }
  .one-pic {
    width: 96% !important; } }

.homepage {
  padding-top: 100px; }

.events {
  padding-top: 90px;
  text-align: center; }

.event {
  text-align: left;
  right: 40%;
  margin: auto;
  margin-top: 40px;
  width: 75%;
  height: 226px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.432);
  border-radius: 5px; }
  .event-image img {
    height: 226px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 5px 0 0 5px; }
  .event-content {
    color: #113f12;
    padding-top: 20px;
    padding-left: 30px; }
    .event-content p {
      font-weight: bold;
      padding-bottom: 5px; }
    .event-content span {
      font-size: 12px;
      font-weight: bold;
      transition: top ease 0.1s;
      position: relative; }
      .event-content span:hover {
        top: -2px; }
      .event-content span a:hover {
        color: #092b0b; }

